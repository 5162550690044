.title.title {
  margin-top: 80px;
  font-weight: bold;
  text-transform: uppercase;
  color: #d9dcd6;
  font-size: 54px;
}

.header-logo {
  grid-row: 1;
  justify-content: center;
}

.question.question {
  text-align: center;
  font-weight: 600;
  color: #d9dcd6;
  margin-top: 64px;
  margin-bottom: 40px;
  font-size: 36px;
}

.animate__animated.animate__fadeInUp.animate__animated.animate__fadeInUp {
  align-self: center;
  background-image: none;
  background-color: #59709b;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #d9dcd6;
  cursor: pointer;
  display: inline-block;
  height: 55px;
  width: 350px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 23px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.animate__animated.animate__fadeInUp.animate__animated.animate__fadeInUp:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
  transform: translate(0, 2px, 0);
}

.animate__animated.animate__fadeInUp.animate__animated.animate__fadeInUp:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
}

.animate__animated.animate__flash {
  -o-animation-delay: 8s;
  animation-delay: 8s;
  animation-duration: 2s;
  -o-animation-duration: 2s;
}

.loading.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animation {
  animation: spin 4s linear infinite;
}

.header-title.header-title {
  font-weight: bold;
  text-transform: uppercase;
  color: #d9dcd6;
  font-size: 54px;
  text-align: center;
  grid-row: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
