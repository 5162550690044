.donation {
  text-decoration: none;
}

.footer.footer {
  position: fixed;
  left: 0;
  bottom: 0;
}

@media (max-width: 1800px) {
  .footer.footer {
    position: static;
  }
}
