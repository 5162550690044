html { 
    background: url('../assets/brick-wall-3.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

.info-header.info-header {
    color: #D9DCD6;
    text-align: center;
    font-size: 36px;
}

.info-1.info-1 {
    color: #D9DCD6;
    margin-top: 32px !important;
    font-size: 24px;
}

.info-2.info-2 {
    color: #D9DCD6;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    font-size: 24px;
}

  