.title.title {
  margin-top: 80px;
  font-weight: bold;
  text-transform: uppercase;
  color: #d9dcd6;
  font-size: 54px;
}

.score.score {
  color: #d9dcd6;
  margin-top: 50px;
  font-size: 24px;
}

.points.points {
  font-weight: bold;
  color: #b88846;
  font-size: 48px;
  margin-bottom: 30px;
}

.summary-paper.summary-paper {
  background-color: #39352b !important;
  border: 25px solid #b88846;
  border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23B88846' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23B88846'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")
    25;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  opacity: 0.75;
  border-radius: 5px;
}

.summary-title.summary-title {
  color: #d9dcd6;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  font-size: 36px;
}

.item-map.item-map {
  color: #d9dcd6;
  font-size: 18px;
}

.item-titles.item-titles {
  color: #d9dcd6;
  font-size: 24px;
  font-weight: bold;
}

.play-again-button.play-again-button {
  align-self: center;
  background-image: none;
  background-color: #59709b;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #d9dcd6;
  cursor: pointer;
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 23px;
  outline: none;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.play-again-button.play-again-button:hover {
  background-color: #334669;
}

.home-page.home-page {
  align-self: center;
  background-image: none;
  background-color: #d9dcd6;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #4a5e82;
  cursor: pointer;
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 23px;
  margin-right: 20px;
  outline: none;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.home-page.home-page:hover {
  border: 2px solid;
  border-color: #4a5e82;
}
