.game-over.game-over {
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  color: #b88846;
  margin-bottom: -15px;
}

.random-line.random-line {
  text-align: center;
  margin-bottom: 16px;
  font-weight: bold;
  color: #39352b;
  font-size: 24px;
}

.point.point {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #39352b;
}

.next-button.next-button {
  align-self: center;
  background-image: none;
  background-color: #4a5e82;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 15px;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  border-color: #b88846;
  cursor: pointer;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 23px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.next-button.next-button:hover {
  background-color: #334669;
}

.your-score.your-score {
  text-align: center;
  font-size: 24px;
  color: #39352b;
  margin-top: 24px;
}
